<template>
  <div class="home">

    <div class="header">
      <div class="icon-container" @click="cleanEvent">
        <i class="fas fa-redo-alt" />
      </div>

      <div class="title">
        Chatters
        <span v-if="!hasOptionsActive('showViwerCount')">({{ filteredEvents.length }}/{{ chatters.length }})</span>
      </div>

      <div class="settings-line">

        <div class="icon-container" @click="openSettings">
          <div class="news-container" v-if="hasNews()">
            1
          </div>
          <i class="fas fa-cog" />
        </div>

        <div class="icon-container" @click="logout" v-if="hasAutoLogin">
          <i class="fas fa-sign-out-alt" />
        </div>

      </div>

      
    </div>

    <div class="container">

      <div class="chat-container">

        <div ref="userList" class="users-list">
          <span v-for="({ type, color, defaultColor, user, date, message, outdated }, i) in filteredEvents" :key="i" 
            :style="`color: ${(hasOptionsActive('color') ? color : defaultColor) || 'white'};`" 
            :class="{ outdated: hasOptionsActive('oldMessage') ? outdated : false }" @click="removeBefore(i)"
          >
            <template v-if="type === 'user'">
              <span class="date">{{ moment(date).format('LT') }}</span>
              {{ user }}
              <span class="message" :title="message">: {{ message }}</span>
            </template>
            <template v-else-if="type === 'raid'">
              <div class="line"></div>
              <span class="raid">Raid de {{ user }}</span>
            </template>
          </span>
        </div>

      </div>

      <div class="settings-container" v-if="showSettings">
        <Settings @close="openSettings" />
      </div>

    </div>

  </div>
</template>

<script>
import { ChatClient } from "dank-twitch-irc"
import { mapState } from 'vuex'

import { mapGetters } from 'vuex'

import colorHelper from 'simple-color-functions'
import gsap from 'gsap'
import moment from 'moment'

import Settings from './settings'

import bots from '../../assets/bots.json'

moment.locale('fr')

export default {
  name: 'Home',

  components: { Settings },

  metaInfo () {
    return {
      title: `Chatters - ${this.$route.params.username.toLowerCase()}`
    }
  },

  data () {
    return {
      chatters: [],
      events: [],
      outdatedDuration: 5,

      config: [],

      hasAutoLogin: false,

      showSettings: false,

      moment
    }
  },

  computed: {

    filteredEvents () {
      return this.events
        .filter(x => {
          return x.user.toLowerCase() != this.$route.params.username.toLowerCase()
        })
        .filter(x => {
          return this.hasOptionsActive('bot') ? !bots.includes(x.user.toLowerCase()) : true
        })
    },

    ...mapGetters(['hasOptionsActive']),
    ...mapState(['options'])
  },

  methods: {

    logout () {
      localStorage.removeItem('autoLogin')
      this.$router.push({ name: 'Home' })
    },

    hasNews () {
      const lastNewsTime = 1634936717499
      const lastNewsValidation = localStorage.getItem('newsValidation')

      return lastNewsValidation < lastNewsTime
    },

    async openSettings () {
      if (this.hasNews()) {
        localStorage.setItem('newsValidation', new Date().getTime())
      }

      this.showSettings = !this.showSettings
    },

    async cleanEvent () {
      this.chatters = []
      this.events = []
      this.saveSession()
    },

    async removeBefore (i) {
      this.events = this.events.slice(i + 1, this.events.lenght)
      this.saveSession()
    },

    async saveSession () {
      const sessionData = JSON.stringify({
        events: this.events,
        chatters: this.chatters,
        updatedAt: new Date,
      })

      localStorage.setItem(`session-${this.$route.params.username.toLowerCase()}`, sessionData)
    },

    async logNewUser (user, message, color) {
      const exist = this.chatters.find(x => x === user)
      if (exist) return

      if (!color || color === '') color = '#ffffff'
      let defaultColor = color;

      let contrast = colorHelper(color).contrast('#18181B')

      while (contrast < 8) {
        color = colorHelper(color).brighten(0.2).hex()
        contrast = colorHelper(color).contrast('#18181B')
      }

      if (this.tl) this.tl.kill()

      const tl = gsap.timeline()
      this.tl = tl

      var setValues = gsap.set('.header', { background: 'red' });
      tl.add(setValues);

      tl.to('.header', { background: '#a93131', duration: 0.1 })
      tl.to('.header', { background: '#18181B', duration: 60 })

      this.chatters.push(user)

      const data = {
        type: 'user',
        color,
        defaultColor,
        message,
        user,
        outdated: false,
        date: new Date()
      }

      setTimeout(() => {
        data.outdated = true;
        this.saveSession()
      }, this.outdatedDuration * 60 * 1000);

      if (this.reverse) {
        this.events.unshift(data)
      } else {
        this.events.push(data)
      }

    }

  },

  async created () {
    this.hasAutoLogin = !!localStorage.getItem('autoLogin')

    setInterval(_ => {
      this.saveSession()
    }, 1000 * 60 * 5)

    const session = JSON.parse(localStorage.getItem(`session-${this.$route.params.username.toLowerCase()}`)) || null

    if (session) {
      const now = new Date()
      const lastUpdate = new Date(session.updatedAt)

      if (now.getTime() - lastUpdate.getTime() > 1000 * 60 * 60) {
        localStorage.removeItem('session')
      } else {
        this.chatters = session.chatters
        this.events = session.events
      }
    }

    let client = new ChatClient({
      connection: {
        type: "websocket",
        secure: true,
      },
    });

    client.on("ready", () => console.log("Successfully connected to chat"));
    client.on("close", (error) => {
      if (error != null) {
        console.error("Client closed due to error", error);
      }
    });

    client.on("PRIVMSG", ({ displayName, messageText, colorRaw }) => {
      this.logNewUser(displayName, messageText, colorRaw)
      this.saveSession()
    });

    client.on("USERNOTICE", (msg) => {
      if (!msg.isRaid()) {
        return;
      }

      if (this.reverse) {
        this.events.unshift({ type: 'raid', user: msg.displayName })
      } else {
        this.events.push({ type: 'raid', user: msg.displayName })
      }

      this.saveSession()
    });

    // See below for more events
    let username = this.$route.params.username
    if (username === '') username = 'alxios'

    console.log(`Connect to ${username}`)

    client.connect();
    client.join(username.toLowerCase());

  }
}
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700');

* {
  font-family: 'Inter', sans-serif;
}

.settings-container {
  position: absolute;
  top: 10px;
  right: 14px;
  width: 320px;
  background: #18181b;
  box-shadow: 0 4px 8px rgba(0,0,0,.4),0 0 4px rgba(0,0,0,.4)!important;
  border-radius: 4px;
}

.login {
  cursor: pointer;
  user-select: none;

  position: absolute;

  right: 200px;

  background: #9147ff;

  color: white;
  font-size: 13px;

  padding: 0 10px;
  height: 30px;

  line-height: 30px;

    border-radius: 5px;

  a {
    color: white;
    text-decoration: none; /* no underline */
  }
}

.container {
  position: relative;

  display: flex;
  flex-direction: row;

  .chat-container {
    width: 100%;
    flex-shrink: 0;
  }
}

.settings-line {
  display: flex;
  flex-direction: row;

  div {
    margin-right: 8px;
  }

  div:last-child {
    margin-right: 0;
  }

}
.stream-info {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  height: 49px;

  .info-container  {
    border-radius: 5px;
    background: #0E0E10;

    font-size: 18px;
    line-height: 15px;
    color: rgb(239, 239, 241);

    height: 40px;
    max-height: 40px;

    min-width: 94px;

    margin: 0 4px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding: 5px 10px;
    box-sizing: border-box;

    font-weight: 500;

    text-align: left;

    &.sessions {
      min-width: 100px;
    }

    .red {
      color: #f65855;
    }

    span {
      font-size: 13px;
      line-height: 13px;
      font-weight: 400;

      color: #adadb8;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #18181B;
  color: #dedee3;

  height: 50px;

  padding: 0 20px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .news-container {
    background: red;
    border-radius: 50%;
    position: absolute;
    width: 18px;
    border: 2px solid #18181b;
    height: 18px;
    left: 18px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;

    color: white;
  }

  .icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    padding: 8px;

    border-radius: 5px;

    height: 34px;
    width: 34px;

    transition: .3s ease all;

    &:hover {
      background: #464649;
    }
  }

  .title {
    position: relative;
  }

}

.home {
  height: 100vh;

  background: #18181B;

  margin: 0;
  padding: 0;

  font-weight: 700;

  overflow: hidden;;
}

div.line {
  border-top: 1px solid  #f65855;
  width: 100%;

  margin-right: 14px;
}

span.raid {
  white-space: nowrap;
  color: #f65855;

  font-size: 13px;

  font-weight: 400;
}

.users-list {
  display: flex;
  flex-direction: column;

  margin-top: 12px;
  max-height: calc(100vh - 62px);

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &>span {
    display: flex;

    align-items: center;;

    height: 26px;
    min-height: 18px;
    line-height: 26px;
    padding: 5px 20px;

    text-transform: capitalize;

    cursor: pointer;

    font-size: 14px;

    transition: .3s ease all;

    font-weight: 700;

    .message {
      color: #d1d1d1;
      font-size: 14px;
      font-weight: 400;

      text-transform: initial;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .date {
      color: #adadb8;
      margin: 0;
      padding: 0;

      margin-right: .5rem;

      font-size: 13px;

      font-weight: 400;

      text-align: left;
    }

    &:nth-child(2n) {
      background: #1F1925;
    }

    &:hover {
      background: #464649;
    }
  }

  .outdated {
    opacity: 0.3;
  }
}
</style>
