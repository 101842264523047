<template>
  <div class="page-container">
    <div class="page-header">
      Paramètres
      <div class="icon-container close-icon" @click="$emit('close')">
        <i class="far fa-times"/>
      </div>
    </div>

    <div class="page-content">
      <div class="options-container">
        <div class="option" :class="{
          'on': option.status === true,
          'off': option.status === false,
        }" v-for="(option, i) in options" :key="i" @click="toggleOption(option)">
          <div class="name">{{ option.name }}</div>
          <div v-show="option.status == true" class="icon-toggle-container">
            <i class="far fa-toggle-on" />
          </div>
          <div v-show="option.status == false" class="icon-toggle-container">
            <i class="far fa-toggle-off" />
          </div>
        </div>

        <a class="support" target="_blank" href="https://paypal.me/alxiostwitch">
          soutenir le projet
          <div class="icon-container">
            <i class="far fa-dollar-sign" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState(['options'])
  },

  methods: {
    toggleOption (option) {
      this.$store.commit('toggleOption', option)
    },
  }
  
}
</script>


<style lang="scss" scoped>

.support {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 12px;
  font-weight: 500;

  margin-top: 18px;

  cursor: pointer;

  margin-right: 4px;

  transition: .3s ease all;

  &:hover {
    color: white;

    .icon-container {
      background: lighten($color: #bfbf4d, $amount: 4);
    }
  }

  .icon-container {
    background: #bfbf4d;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin-left: 8px;
    color: #18181b;

    text-align: right;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.page-container {
  position: relative;

  margin: 12px;
}

.page-header {
  font-size: 14px;
  color: white;
  text-align: center;
}

.options-container {
  margin: 0 auto;
  margin-top: 24px;

  display: flex;
  flex-direction: column;

  max-width: 324px;
}

.option {
  display: flex;
  align-items: center;

  height: 24px;

  user-select: none;

  font-size: 13px;
  font-weight: 400;

  color: white;

  justify-content: space-between;

  margin: 4px 0;

  cursor: pointer;
}

.option.on {
  .icon-toggle-container {
    color: #8af78a;
  }
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  padding: 8px;

  border-radius: 5px;

  height: 24px;
  width: 24px;

  transition: .3s ease all;

  &:hover {
    background: #464649;
  }
}

.close-icon {
  position: absolute;
  top: -4px;
  right: 0;
}

.icon-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  padding: 8px;

  border-radius: 5px;

  color: #b1b1b1;

  &:hover {
    color: white;
  }

  height: 34px;
  width: 34px;

  transition: .3s ease all;

  svg {
    height: 26px;
    width: 26px;
  }

}

</style>