var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"icon-container",on:{"click":_vm.cleanEvent}},[_c('i',{staticClass:"fas fa-redo-alt"})]),_c('div',{staticClass:"title"},[_vm._v(" Chatters "),(!_vm.hasOptionsActive('showViwerCount'))?_c('span',[_vm._v("("+_vm._s(_vm.filteredEvents.length)+"/"+_vm._s(_vm.chatters.length)+")")]):_vm._e()]),_c('div',{staticClass:"settings-line"},[_c('div',{staticClass:"icon-container",on:{"click":_vm.openSettings}},[(_vm.hasNews())?_c('div',{staticClass:"news-container"},[_vm._v(" 1 ")]):_vm._e(),_c('i',{staticClass:"fas fa-cog"})]),(_vm.hasAutoLogin)?_c('div',{staticClass:"icon-container",on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-sign-out-alt"})]):_vm._e()])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"chat-container"},[_c('div',{ref:"userList",staticClass:"users-list"},_vm._l((_vm.filteredEvents),function(ref,i){
var type = ref.type;
var color = ref.color;
var defaultColor = ref.defaultColor;
var user = ref.user;
var date = ref.date;
var message = ref.message;
var outdated = ref.outdated;
return _c('span',{key:i,class:{ outdated: _vm.hasOptionsActive('oldMessage') ? outdated : false },style:(("color: " + ((_vm.hasOptionsActive('color') ? color : defaultColor) || 'white') + ";")),on:{"click":function($event){return _vm.removeBefore(i)}}},[(type === 'user')?[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.moment(date).format('LT')))]),_vm._v(" "+_vm._s(user)+" "),_c('span',{staticClass:"message",attrs:{"title":message}},[_vm._v(": "+_vm._s(message))])]:(type === 'raid')?[_c('div',{staticClass:"line"}),_c('span',{staticClass:"raid"},[_vm._v("Raid de "+_vm._s(user))])]:_vm._e()],2)}),0)]),(_vm.showSettings)?_c('div',{staticClass:"settings-container"},[_c('Settings',{on:{"close":_vm.openSettings}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }